/*
|
| Importing Libs
|------------------
*/
import Swiper from 'swiper/bundle';
import gsap from 'gsap';

/*
|
| Importing Components
|-----------------------
*/
import Kira from '@components/kira';
import Menu from '@components/menu';
import Overlay from '@components/overlays';
import OffersSelector from '@components/offers-selector'

/*
|
| Importing Utils
|-------------------
*/
import * as app from '@utils/global';
import Router from '@utils/router';
import Ajax from '@utils/ajax';
import Listing from '@utils/listing';

/*
|
| Importing App files
|----------------------
*/
import main from '@pages/main';
import news from '@pages/news';
import animations from '@pages/animations';

/*
|
| Tealium
|----------------------
*/
import tForms from '@tealium/forms';
import tConfigurateur from '@tealium/configurateur';
import tPromotionInterne from '@tealium/promotion-interne';
import tLinks from '@tealium/links';


/*
|
| Routing
|----------
*/
const routes = new Router([
    {
        file: animations,
        dependencies: [app, gsap, Menu, Kira]
    },
    {
        file: main,
        dependencies: [app, Overlay, Swiper],
    },
    {
        file: news,
        dependencies: [app, Listing],
        resolve: '.news-container'
    },
    {
        file: OffersSelector,
        resolve: '.block-nos-offres'
    },
    {
        file: tForms,
        resolve: 'form'
    },
    {
        file: tConfigurateur,
        resolve: '.block-nos-offres'
    },
    {
        file: tPromotionInterne,
        resolve: 'a.tealium-event-promotion'
    },
    {
        file: tLinks
    }
]);

/*
|
| Run
|------
*/
(($) => { routes.load() })(jQuery);