export default {
    init: () => {

        const selectElements = document.querySelectorAll('.block-nos-offres select')
    
        function toggleDropdownClass(selectElement) {
            if (selectElement.value !== '') {
                selectElement.classList.add('selected')
            } else {
                selectElement.classList.remove('selected')
            }
    
            const questionOption = selectElement.options[0]
    
            if (selectElement.value !== '') {
                questionOption.style.display = 'none'
            } else {
                questionOption.style.display = 'block'
            }
        }
    
        function addOpenClass(selectElement) {
            selectElement.classList.add('open')
        }
    
        function removeOpenClass(selectElement) {
            selectElement.classList.remove('open')
        }
        
        selectElements.forEach(selectElement => {
            toggleDropdownClass(selectElement);

            selectElement.addEventListener('change', () => {
                toggleDropdownClass(selectElement)
            })
    
            selectElement.addEventListener('focus', () => {
                addOpenClass(selectElement)
            })
    
            selectElement.addEventListener('blur', () => {
                removeOpenClass(selectElement);
            })
        })
    }
}