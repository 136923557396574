export default {
    init: () => {
        document.addEventListener('DOMContentLoaded', () => {
            const lienUrl = document.querySelector('#lien-url')
            const activitesSelect = document.querySelector('#activites')
            const metiersSelect = document.querySelector('#metiers')

            if (lienUrl) {
                lienUrl.addEventListener('click', (e) => {
                    if (!lienUrl.classList.contains('disable') && lienUrl.href && lienUrl.href !== '' && lienUrl.href !== '#') {
                        const marketName = activitesSelect.options[activitesSelect.selectedIndex].value
                        const jobName = metiersSelect.options[metiersSelect.selectedIndex].value
                        const tealiumData = {
                            'tealium_event': 'configurator_access',
                            'market_name': marketName,
                            'job_name': jobName
                        }

                        if (typeof utag === 'object' && typeof utag.link === 'function') {
                            utag.link(tealiumData)
                        } else {
                            console.warn('Tealium utag.link function not available, data not sent:', tealiumData)
                        }
                    }
                })
            } else {
                console.warn('Required elements not found in DOM for configurateur.js')
            }
        })
    }
}