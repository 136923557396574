import { getLang, updateURL, delegateEventListener, AJAX_TYPE_FILTER, AJAX_TYPE_LOAD_MORE, AJAX_TYPE_PAGINATE } from '@utils/global'
import Ajax from '@utils/ajax'

const DEFAULTS = {
    multilingual: false,
    updateURL: true,
    submitOnChange: true,
    selectors: {
        page: '.grid',
        item: '.grid-item',
        loadMore: '.load-more',
        pagination: '.pagination',
    }
}

export default class Listing {
    constructor(filters, container, options = {}) {
        this.bindMethods()

        this.filters = filters
        this.id = filters.id
        this.ajaxRoute = filters.hasAttribute('action') ? filters.action : ''
        this.ajaxMethod = filters.hasAttribute('method') ? filters.method : 'GET'
        this.container = container
        this.options = {
            ...DEFAULTS,
            ...options,
        }

        delete this.options.selectors

        this.selectors = {
            ...DEFAULTS.selectors,
            ...options.selectors
        }
        this.xhr

        this.filters.addEventListener('submit', this.submit)
        this.filters.addEventListener('reset', this.reset)

        // Automatically submit on input change
        if (this.options.submitOnChange) {
            delegateEventListener(this.filters, 'change', 'input, select', this.submit)
            delegateEventListener(document, 'change', `[form="${this.id}"]`, this.submit)
        }

        // Setup Load more behavior
        this.totalPages = this.setPages()
        delegateEventListener(container, 'click', `${this.selectors.loadMore} [type="submit"]`, this.loadMore)

        // Setup pagination behavior
        delegateEventListener(container, 'click', `${this.selectors.pagination} button`, this.paginate)
    }

    bindMethods() {
        this.submit = this.submit.bind(this)
        this.loadMore = this.loadMore.bind(this)
        this.paginate = this.paginate.bind(this)
        this.reset = this.reset.bind(this)
        this.load = this.load.bind(this)
        this.dispatchEvent = this.dispatchEvent.bind(this)
    }

    get pages() {
        return this.selectors.page ? this.container.querySelectorAll(this.selectors.page) : null
    }

    get items() {
        return this.selectors.item ? this.container.querySelectorAll(this.selectors.item) : null
    }

    get loadMoreElement() {
        return this.selectors.loadMore ? this.container.querySelector(this.selectors.loadMore) : null
    }

    get paginationElement() {
        return this.selectors.pagination ? this.container.querySelector(this.selectors.pagination) : null
    }

    setPages() {
        const pages = this.container.dataset.pages ?? 1

        this.container.removeAttribute('data-pages')

        return parseInt(pages)
    }

    load(type = AJAX_TYPE_FILTER, page = 1) {
        this.dispatchEvent('beforeload', type)

        const data = new FormData(this.filters)

        if (this.options.updateURL) {
            let url = location.protocol + '//' + location.host + location.pathname

            if (type !== AJAX_TYPE_LOAD_MORE) {
                url = location.protocol + '//' + location.host + (location.pathname.replace(/page\/(\d*)\//, ''))
            }

            if (type === AJAX_TYPE_PAGINATE && page > 1) {
                url += `page/${page}/`
            }

            updateURL(url, data)
        }

        this.dispatchEvent('loadstart', type, data)

        if (this.options.multilingual) data.append('lang', getLang())

        if (type !== AJAX_TYPE_FILTER && page > 1) data.append('page', page)

        if (this.xhr) this.xhr.abort()

        this.xhr = new Ajax({
            url: this.ajaxRoute,
            method: this.ajaxMethod,
            data: data,
            onSuccess: response => {
                this.dispatchEvent('loadend', type)
                if (type == AJAX_TYPE_LOAD_MORE) {
                    if (this.paginationElement) { this.paginationElement.remove() }
                    if (this.loadMoreElement) { this.loadMoreElement.remove() }

                    this.pages[this.pages.length - 1].insertAdjacentHTML('afterend', response.html)
                } else {
                    this.container.innerHTML = response.html
                    this.totalPages = response.pages
                }

                this.dispatchEvent('success', type, response)
            },
            onError: (status, text) => {
                this.dispatchEvent('error', status, text)
            }
        })
    }

    reset(e) {
        document.querySelectorAll(`form[id="${this.id}"] :checked, [form="${this.id}"]:checked`).forEach(input => {
            input.checked = false
        })

        this.submit(e)
    }

    submit(e) {
        if (e && e.preventDefault) e.preventDefault()

        if (!this.filters.checkValidity()) return

        this.load(AJAX_TYPE_FILTER)
    }

    loadMore(e) {
        e.preventDefault()
        this.load(AJAX_TYPE_LOAD_MORE, this.pages.length + 1)
    }

    paginate(e) {
        e.preventDefault()

        this.load(AJAX_TYPE_PAGINATE, e.target.closest('button').dataset.page)
    }

    dispatchEvent(event, ...data) {
        this.filters.dispatchEvent(new CustomEvent(event, {
            bubbles: true,
            detail: data
        }))
    }
}
