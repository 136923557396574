export default {
    init: () => {
        document.addEventListener('click', event => {
            const target = event.target.closest('a')
            if (!target || !target.getAttribute('href') || target.getAttribute('href') === '#') return

            const href = target.getAttribute('href')
            const fullUrl = href.startsWith('http') ? href : `${window.location.origin}${href}`
            const linkDomain = new URL(fullUrl).hostname
            const linkID = target.id || 'undefined'
            const outbound = !fullUrl.startsWith(window.location.origin)
            
            let linkZone = 'content'

            if (target.closest('header#header .primary-menu') || target.closest('#menu-mobile') || target.closest('#menu-mobile2')) {
                linkZone = 'header'
            } else if (target.closest('#topmenu')) {
                linkZone = 'subheader'
            } else if (target.closest('.menu-home') || target.closest('.menu-market')) {
                linkZone = 'contextual_menu'
            } else if (target.closest('.breadcrumb')) {
                linkZone = 'breadcrumb'
            } else if (target.closest('footer#footer')) {
                linkZone = 'footer'
            }

            const tealiumData = {
                'tealium_event': outbound ? 'external_link' : 'internal_link',
                'link_url': fullUrl,
                'link_domain': linkDomain,
                'link_id': linkID,
                'link_zone': linkZone
            }

            sendTealiumData(tealiumData)

        }, {capture: true})

        const sendTealiumData = data => {
            if (window.utag && typeof window.utag.link === 'function') {
                window.utag.link(data)
            } else {
                console.warn('Tealium utag.link function not available, data not sent:', data)
            }
        }
    }
}