/*-------- Multilingual --------*/

// GET LANG
export const getLang = () => document.documentElement.hasAttribute('lang') ? document.documentElement.lang.split('-')[0] : 'fr'


/*-------- Utils --------*/

// HOVER ENABLED
export const hoverEnabled = () => window.matchMedia('(hover: hover)').matches

// MOTION ENABLED
export const motionEnabled = () => window.matchMedia('(prefers-reduced-motion: no-preference)').matches

// DUMP
export const dump = (value) => console.log(value)

// DISPATCH EVENT
export const dispachEvent = (element, event, data = {}) => element.dispatchEvent(new CustomEvent(event, { detail: data }))

// DELEGATE EVENT LISTENER
export const delegateEventListener = (element, event, selector, callback) => {
    element.addEventListener(event, (e) => {
        if (!element.contains(e.target) || !e.target.closest(selector)) return
        callback.call(e.target, e)
    })
}

// IS ELEMENT DEFINED
export const isDefined = (item) => typeof item !== 'undefined'

// ELEMENT EXISTS
export const exist = (element) => element.length

// DEBOUNCE
export const debounce = (func, wait, immediate = false) => {
    let timeout

    return function executedFunction() {
        let context = this
        let args = arguments

        let later = function () {
            timeout = null
            if (!immediate) func.apply(context, args)
        }

        let callNow = immediate && !timeout

        clearTimeout(timeout)

        timeout = setTimeout(later, wait)

        if (callNow) func.apply(context, args)
    }
}

// ADD DATA TO URL
export const updateURL = data => {
    const url = location.protocol + '//' + location.host + location.pathname
    const query = new URLSearchParams(data)
    const paramsToRemove = []

    query.forEach((val, key) => {
        if (val == '') paramsToRemove.push(key)
    })

    paramsToRemove.forEach(param => {
        query.delete(param)
    })

    const queryString = query.toString()

    history.replaceState({}, document.title, `${url}${queryString ? `?${queryString}` : ''}`)
}


/*-------- Scroll --------*/

let callsToUnlock = 0

// LOCK
export const lockScroll = () => {
    if (callsToUnlock === 0) document.body.style.overflow = 'hidden'

    callsToUnlock++
}

// UNLOCK
export const unlockScroll = (force = false) => {
    callsToUnlock = force ? 0 : Math.max(0, callsToUnlock - 1)

    if (callsToUnlock === 0) document.body.style.overflow = ''
}

/*-------- Cookies --------*/

// GET
export const getCookie = (cname) => {
    var name = cname + "="
    var decodedCookie = decodeURIComponent(document.cookie)
    var ca = decodedCookie.split(';')
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i]
        while (c.charAt(0) == ' ') {
            c = c.substring(1)
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length)
        }
    }

    return null
}

// SET
export const setCookie = (name, value, nbDays) => {
    const date = new Date()
    date.setTime(date.getTime() + (nbDays * 24 * 60 * 60 * 1000))
    const expires = "expires=" + date.toUTCString()
    document.cookie = name + "=" + value + ";" + expires + ";path=/"
}

// DELETE
export const deleteCookie = (name) => document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:01 GMT;'


/*-------- Lazy loader --------*/
export const lazyLoader = new IntersectionObserver((entries, observer) => {
    entries.forEach((entry) => {
        if (!entry.isIntersecting) return

        if (entry.target.hasAttribute('data-src')) {
            entry.target.src = entry.target.dataset.src
            entry.target.removeAttribute('data-src')
        }

        if (entry.target.hasAttribute('data-bg')) {
            entry.target.style.backgroundImage = `url(${entry.target.dataset.bg})`
            entry.target.removeAttribute('data-bg')
        }

        observer.unobserve(entry.target)
    })
})

/*-------- CONSTANTS --------*/
export const overlays = []
export const FOCUSABLE_ELEMENTS = ':where(a, button, input:not([type="hidden"]), select, textarea, iframe, [tabindex], [contentEditable="true"])'
export const AJAX_TYPE_FILTER = 0
export const AJAX_TYPE_LOAD_MORE = 1
export const AJAX_TYPE_PAGINATE = 2