export default {
    init: () => {
        const forms = document.querySelectorAll('.gform_wrapper')

        forms.forEach(form => {
            form.addEventListener('focusin', function(e) {
                if (!form.hasAttribute('data-tealium-tracked')) {
                    const formId = form.id.split('_').pop()
                    const gformTitle = form.querySelector('.gform_heading .gform_title')?.innerHTML
                    const gformDescription = form.querySelector('.gform_heading .gform_description')?.innerHTML

                    const tealiumData = {
                        'tealium_event': 'form_start',
                        'form_id': formId,
                        'form_step': '1',
                        'gform_title': gformTitle,
                        'gform_description': gformDescription
                    }

                    if (typeof utag === 'object' && typeof utag.link === 'function') {
                        utag.link(tealiumData)
                    } else {
                        console.warn('Tealium utag.link function not available, form data not sent:', tealiumData)
                    }

                    form.setAttribute('data-tealium-tracked', 'true')
                }
            })
        })
    }
}