export default {
	init: (app, Overlay, Swiper) => {

		/*
		|
		| Menu market
		|-----------------
		*/
		if ($(".menu-market").length) {
			let menuMarket = $('.menu-market').height();
			menuMarket = (- menuMarket / 2 - 15 + 'px');
			$('.menu-market').css('margin-top', menuMarket);
		}

		/*
		|
		| Menu home
		|-----------------
		*/
		if ($(".menu-home").length) {
			function positionMenuHome() {
				let menuHome = $('.menu-home').height();
				menuHome = (- menuHome / 2 - 15 + 'px');
				$('.menu-home').css('margin-top', menuHome);
			}
			$(document).ready(function () {
				positionMenuHome();
			});
			$(window).on('scroll', function () {
				positionMenuHome();
			});
			$(window).on('resize', function () {
				positionMenuHome();
			});
		}

		/*
		|
		| Menu hide
		|-----------------
		*/

		$(document).ready(function () {
			var lastScrollTop = 0;
			var delta = 5;
			var navbarHeight = $('#header').outerHeight();

			$(window).scroll(function () {
				var scroll = $(this).scrollTop();

				if (Math.abs(lastScrollTop - scroll) <= delta) return;

				if (scroll > lastScrollTop && scroll > navbarHeight) {
					$('#header').addClass('header-hidden');
				} else {
					$('#header').removeClass('header-hidden');
				}

				lastScrollTop = scroll;
			});

			// Ajout de la classe top
			function updateHeaderClass() {
				var scrollPosition = $(window).scrollTop();

				if (scrollPosition < 5) {
					$('#header').addClass('top-header');
				} else {
					$('#header').removeClass('top-header');
				}
			}
			updateHeaderClass();
			$(window).scroll(function () {
				updateHeaderClass();
			});
		});



		/*
		|
		| Primary sous-menu
		|-----------------
		*/
		if ($(".primary-menu-parent").length) {
			$(".primary-menu .menu-item-has-children").click(function (event) {
				event.preventDefault();

				var menuId = $(this).find(".item-link").data("id");

				if ($(this).hasClass("active")) {
					$("#" + menuId).slideUp(300);
					$(this).removeClass("active");
					$('.primary-menu .primary-menu-child').removeClass("open");
				} else {

					$(".primary-menu .item-child").slideUp(300);
					$(".primary-menu .menu-item-has-children").removeClass("active");
					$("#" + menuId).slideDown(300);
					$(this).addClass("active");
					$('.primary-menu .primary-menu-child').addClass("open");
				}

				event.stopPropagation();
			});

			$(document).on("click", function (event) {
				if (!$(event.target).closest('.primary-menu-parent').length) {
					$(".primary-menu .item-child").slideUp(300);
					$(".primary-menu .menu-item-has-children").removeClass("active");
					$('.primary-menu .primary-menu-child').removeClass("open");
				}
			});
		}


		/*
		|
		| Sous-menu home
		|-----------------
		*/

		if ($(".menu-home").length) {
			$(".trigger-menu-home").hover(function () {
				/* Open menu */
				let id = $(this).data("id");
				let child = $(this).data("child");

				if (child == true) {
					/* Hide other child */
					$('.menu-home .item-child').css("display", "none");

					/* Show good child */
					$('.menu-home .menu-child').css("display", "block");
					$('#' + id).css("display", "block");
				} else {
					$('#' + id).css("display", "none");
					$('.menu-home .menu-child').css("display", "none");
				}
			});

			$(".menu-home").mouseleave(function () {
				$('.menu-home .menu-child').css("display", "none");
			});
		}

		/*
		|
		| Footer fixed
		|-----------------
		*/
		function footerMrg() {
			let mrg = jQuery("#footer").height();

			let width = jQuery("#footer").width();

			if (width > 711) {
				jQuery('.page-content').css('margin-bottom', mrg + 'px');
			} else {
				jQuery('.page-content').css('margin-bottom', 'initial');
			}

			if (jQuery(window).scrollTop() < 100) {
				jQuery('#footer').css('display', 'none');
			} else {
				jQuery('#footer').css('display', 'block');
			}

		}
		jQuery(document).ready(function () {
			footerMrg();
		});
		jQuery(window).on('scroll', function () {
			footerMrg();
		});
		jQuery(window).on('resize', function () {
			footerMrg();
		});


		/*
		|
		| CMS scroll anchor
		|-----------------
		*/
		$(document).on('click', '.cmsanchor', function (event) {
			event.preventDefault();

			$('html, body').animate({
				scrollTop: $($.attr(this, 'href')).offset().top - 65
			}, 500);
		});

		/*
		|
		| Fixed CMS scroll anchor
		|-----------------
		*/
		if ($(".sommaire").length) {
			var summary = $(".sommaire");
			var rightColumn = $(".right-column");
			function CMSScroll() {
				var scrollPos = $(window).scrollTop();
				var rightColumnBottom = rightColumn.offset().top + rightColumn.height() - summary.height();

				if (scrollPos < rightColumnBottom) {
					summary.css({
						'position': 'sticky',
						'top': '65px',
					});
				} else {
					summary.css({

					});
				}
			}

			$(document).ready(function () {
				CMSScroll();
			});

			$(window).on('scroll', function () {
				CMSScroll();
			});
		}

		/*
		|
		| Swiper activ offre
		|-----------------
		*/

		if ($(".swiper-activ-offre").length) {
			var swiperactivoffre = new Swiper(".swiper-activ-offre", {
				slidesPerView: 3,
				paginationClickable: true,
				spaceBetween: 24,
				breakpoints: {
					0: {
						slidesPerView: 1.2,
						spaceBetween: 16
					},
					768: {
						slidesPerView: 2
					},
					992: {
						slidesPerView: 2
					},
					1200: {
						slidesPerView: 3
					},
				},
				navigation: {
					nextEl: '.swiper-button-next',
					prevEl: '.swiper-button-prev',
				},
				on: {
					init: function () {
						checkNavigation(this);
					},
					resize: function () {
						checkNavigation(this);
					}
				}
			});

			function checkNavigation(swiper) {
				var slidesPerView = swiper.params.slidesPerView;
				var totalSlides = swiper.slides.length;

				if (totalSlides <= slidesPerView) {
					$('.swiper-button').hide();
				} else {
					$('.swiper-button').show();
				}
			}
		}


		/*
		|
		| Swiper 4 ressources
		|-----------------
		*/

		if ($(".swiper-4-ressources").length) {
			var swiper4ressources = new Swiper(".swiper-4-ressources", {
				slidesPerView: 3.1,
				paginationClickable: true,
				spaceBetween: 24,
				breakpoints: {
					0: {
						slidesPerView: 1.1,
						spaceBetween: 16
					},
					768: {
						slidesPerView: 2.1
					},
					992: {
						slidesPerView: 2.1
					},
					1200: {
						slidesPerView: 3.1
					},
				},
				navigation: {
					nextEl: '.swiper-button-next',
					prevEl: '.swiper-button-prev',
				}
			});
		}

		/*
		|
		| Swiper temoignages
		|-----------------
		*/

		if ($(".swiper-testimonials").length) {
			var swipertestimonials = new Swiper(".swiper-testimonials", {
				slidesPerView: 3.1,
				paginationClickable: true,
				spaceBetween: 24,
				breakpoints: {
					0: {
						slidesPerView: 1,
						spaceBetween: 16
					},
					768: {
						slidesPerView: 2.1
					},
					992: {
						slidesPerView: 2.1
					},
					1200: {
						slidesPerView: 3.1
					},
				},
				navigation: {
					nextEl: '.swiper-button-next',
					prevEl: '.swiper-button-prev',
				}
			});
		}

		/*
		|
		| Swiper activ ressources
		|-----------------
		*/

		if ($(".swiper-activ-ressources").length) {
			var swiperactivressources = new Swiper(".swiper-activ-ressources", {
				slidesPerView: 3.1,
				paginationClickable: true,
				spaceBetween: 24,
				breakpoints: {
					0: {
						slidesPerView: 1,
						spaceBetween: 16
					},
					768: {
						slidesPerView: 2.1
					},
					992: {
						slidesPerView: 2.1
					},
					1200: {
						slidesPerView: 3.1
					},
				},
				navigation: {
					nextEl: '.swiper-button-next',
					prevEl: '.swiper-button-prev',
				}
			});

			/* hide swiper bouton if < 3  */
			let nbr = swiperactivressources.slides.length;
			if (nbr <= 3) {
				$(".swiper-button").css('display', 'none');
			}
		}

		/*
		|
		| Swiper ressources gratuites
		|-----------------
		*/

		if ($(".swiper-ressources-gratuites").length) {
			var swiperactiveressourcesgrat = new Swiper(".swiper-ressources-gratuites", {
				slidesPerView: 3.1,
				paginationClickable: true,
				spaceBetween: 24,
				breakpoints: {
					0: {
						slidesPerView: 1,
						spaceBetween: 16
					},
					768: {
						slidesPerView: 2.1
					},
					992: {
						slidesPerView: 2.1
					},
					1200: {
						slidesPerView: 3.1
					},
				},
				navigation: {
					nextEl: '.swiper-button-next',
					prevEl: '.swiper-button-prev',
				},
				on: {
					init: function () {
						let nbr = this.slides.length;
						if (nbr <= 3) {
							$(".swiper-button-ressources-gratuites").css('display', 'none');
						}
					}
				}
			});
		}
		

		/*
		|
		| Swiper partenaires
		|-----------------
		*/

		if ($(".partenaires").length) {
			document.addEventListener("DOMContentLoaded", function () {
				var tabs = document.querySelectorAll('.group');
				var swipers = [];
				tabs.forEach(function (tab) {
					var swiper = new Swiper(tab.querySelector('.swiper-partenaires'), {
						loop: true,
						centeredSlides: true,
						grabCursor: true,
						slidesPerView: 6,
						speed: 1800,
						observer: true,
						observeParents: true,
						watchSlidesVisibility: true,
						watchSlidesProgress: true,
						autoplay: {
							delay: 0,
						},
						breakpoints: {
							0: {
								slidesPerView: 2
							},
							768: {
								slidesPerView: 3
							},
							992: {
								slidesPerView: 5
							},
							1200: {
								slidesPerView: 6
							},
						},
					});
					swipers.push(swiper);
				});

				var tabTriggers = document.querySelectorAll('.tabs-nav a');
				tabTriggers.forEach(function (trigger, index) {
					trigger.addEventListener('click', function () {
						for (var i = 0; i < swipers.length; i++) {
							if (i !== index) {
								swipers[i].autoplay.stop();
								swipers[i].slideTo(0); // Réinitialiser le swiper actif à la première diapositive
							} else {
								swipers[i].autoplay.start();

							}
						}
					});
				});
			});
		}






		/*
		|
		| Tabs partenaires
		|-----------------
		*/
		if ($(".tabs").length) {
			$('.tabs-stage .group').hide();
			$('.tabs-stage .group:first').show();
			$('.tabs-nav li:first').addClass('tab-active');

			$('.tabs-nav a').on('click', function (event) {
				event.preventDefault();

				$('.tabs-nav li').removeClass('tab-active');
				$(this).parent().addClass('tab-active');
				$('.tabs-stage .group').hide();
				$($(this).attr('href')).show();
			});
		}

		/*
		|
		| Scroll top
		|-----------------
		*/
		if ($(".up").length) {
			/* Show element */
			$(window).scroll(function () {
				var scrollTop = $(window).scrollTop();
				if (scrollTop >= 100) {
					$('.up').addClass('active');
				} else {
					$('.up').removeClass('active');
				}
			});

			/* Scroll top */
			$("a[href='#top']").click(function () {
				$("html, body").animate({ scrollTop: 0 }, "slow");
				return false;
			});
		}

		/*
		|
		| FAQ
		|-------------------
		|
		*/
		if ($(".accordion").length) {
			const accHeads = document.querySelectorAll(
				".accordion-item-header"
			);

			accHeads.forEach((accHead) => {
				accHead.addEventListener("click", (event) => {

					const currentlyActiveaccHead = document.querySelector(
						".accordion-item-header.active"
					);
					if (
						currentlyActiveaccHead &&
						currentlyActiveaccHead !== accHead
					) {
						currentlyActiveaccHead.classList.toggle("active");
						currentlyActiveaccHead.nextElementSibling.style.maxHeight = 0;
					}
					accHead.classList.toggle("active");
					const accordionItemBody = accHead.nextElementSibling;
					if (accHead.classList.contains("active")) {
						accordionItemBody.style.maxHeight = accordionItemBody.scrollHeight + "px";
					} else {
						accordionItemBody.style.maxHeight = 0;
					}
				});
			});
		}


		/*
		|
		| Select offres
		|-----------------
		*/
		jQuery(document).ready(function ($) {
			if ($("#lien-url").length) {
				$('#lien-url').addClass('disable').removeAttr('href');
				function remise_a_zero() {
					$('#lien-url').addClass('disable').removeAttr('href');
					$('#metiers').val('');
				}

				function afficherOptionsMetiers(selectedActivity) {
					remise_a_zero();

					if (selectedActivity !== '') {
						$('#metiers').prop('disabled', false);

						$('#metiers option[value=""]').hide();
						$('#metiers option[value!=""]').hide();
						$('#metiers option[data-activite="' + selectedActivity + '"]').show();
					} else {
						$('#metiers').prop('disabled', true);

						$('#metiers option[value=""]').show();
						$('#metiers option[value!=""]').show();
					}
				}

				$('#activites').change(function () {
					var selectedActivity = $(this).val();
					afficherOptionsMetiers(selectedActivity);
				});

				var selectedActivityOnLoad = $('#activites').val();
				afficherOptionsMetiers(selectedActivityOnLoad);

				$('#metiers').change(function () {
					var selectedURL = $(this).find('option:selected').data('url');
					$('#lien-url').attr('href', selectedURL).removeClass('disable');
				});
			}
		});


		/*
		|
		| Loading Lottie animations 
		|-----------------
		*/
		jQuery(document).ready(function ($) {
			if ($("#animation1").length) {
				document.querySelectorAll('.anim').forEach((cell, index) => {
					const jsonPath = $(cell).data('json'); // Utilisation de jQuery pour accéder à l'attribut data-json

					const randomDelay = Math.random() * (2000 - 100) + 100;

					setTimeout(() => {
						const animation = lottie.loadAnimation({
							container: cell,
							renderer: 'svg',
							loop: false,
							autoplay: true,
							path: jsonPath
						});

						animation.setSpeed(0.5);
					}, randomDelay);
				});
			}
		});

		/*
		|
		| Video testimonials
		|-----------------
		*/
		if ($(".video-cover").length) {
			$(".video-cover").on("click", function () {
				var $videoContainer = $(this).parent();
				$(this).hide();
				$videoContainer.find("iframe").show();
				var iframe = $videoContainer.find("iframe")[0];
				iframe.contentWindow.postMessage('{"event":"command","func":"playVideo","args":""}', '*');
			});
		}

		/*
		|
		| Lien retour ressource
		|-----------------
		*/
		if ($(".link-back").length) {
			var currentUrl = window.location.href;
			var parameter = 'marketjob';

			if (currentUrl.indexOf(parameter) > -1) {
				$('.link-back').attr('href', document.referrer);
			} else {
				$('.link-back').attr('href', '/ressources');
			}
		}

		/*
		|
		| Lien retour ESG
		|-----------------
		*/
		if ($(".link-back-esg").length) {
			$(".link-back-esg").on("click", function (e) {
				e.preventDefault();
				if (document.referrer !== "") {
					window.history.back();
				} else {
					window.location.href = '/';
				}
			});
		}

		/*
		|
		| Anchor livre blanc
		|-----------------
		*/
		$(".anchor-bb-link").on('click', function (event) {
			if (this.hash !== "") {
				event.preventDefault();
				var hash = this.hash;
				$('html, body').animate({
					scrollTop: $(hash).offset().top - 150
				}, 800, function () {
					window.location.hash = hash;
				});
			}
		});

		/*
		|
		| Sommary mobile
		|-----------------
		*/
		function updateMobileMenu() {
			if ($(window).width() < 960) {
				$('.sommary').hide();
				$('.mobile-menu').remove();

				$('.sommaire').append('<select class="mobile-menu"></select>');

				var anchor = 1;

				// Ajout de la première option par défaut
				$('.mobile-menu').append('<option value="" selected>Sommaire</option>');

				$('.sommary li').each(function () {
					var title = $(this).find('a').text();
					$('.mobile-menu').append('<option value="#anchor-' + anchor + '">' + title + '</option>');
					anchor++;
				});

				$('.mobile-menu').change(function () {
					var selectedAnchor = $(this).val();
					if (selectedAnchor !== "") {
						$('html, body').animate({
							scrollTop: $(selectedAnchor).offset().top + 200
						}, 800);
					}
				});
			} else {
				$('.sommary').show();
				$('.mobile-menu').remove();
			}
		}

		$(document).ready(function () {
			updateMobileMenu();

			$(window).resize(function () {
				updateMobileMenu();
			});
		});



		document.addEventListener('DOMContentLoaded', function () {
			var telInputs = document.querySelectorAll('input[type="tel"]');

			telInputs.forEach(function (input) {
				input.addEventListener('input', function (event) {
					var inputValue = event.target.value;
					// Utilisez une expression régulière pour permettre uniquement les chiffres
					var sanitizedValue = inputValue.replace(/[^\d]/g, '');
					event.target.value = sanitizedValue;
				});
			});
		});


		/*-------- Popin ESG --------*/
		$("#openPopin").click(function () {
			$("#form-bb").fadeIn();
			$(".overlay-form-bb").fadeIn();
		});

		$("#closePopin").click(function () {
			$("#form-bb").fadeOut();
			$(".overlay-form-bb").fadeOut();
		});

		/*-------- ESG Offre Read more --------*/
		$(document).ready(function () {
			function toggleLinks() {
				if ($(window).width() < 712 && $('.links li').length > 4) {
					$('#voir-plus').show();
					$('.links li:gt(3)').hide();
				} else {
					$('#voir-plus').hide();
					$('.links li').show();
				}
			}

			toggleLinks();

			$(window).resize(toggleLinks);

			$('#voir-plus').click(function () {
				$('.links li:gt(3)').slideDown('slow');
				$(this).hide();
			});
		});


		/*-------- Remove type script --------*/
		document.addEventListener("DOMContentLoaded", function () {
			var emptyScripts = document.querySelectorAll("script[type='text/javascript']:empty");
			emptyScripts.forEach(function (script) { script.remove(); });
		});

		/*-------- Lazy loading --------*/
		document.querySelectorAll('[data-src], [data-bg]').forEach((item) => app.lazyLoader.observe(item))

		/*-------- Modals --------*/
		document.querySelectorAll('.modal').forEach((item) => {
			app.overlays[item.id] = new Overlay(item)
		})

		/*-------- Add banner-menu-market --------*/
		$(document).ready(function () {
			if ($('.menu-market').length) {
				$('.banner').addClass('banner-menu-market');

				var itemCount = $('.menu-market .item').length;

				if (itemCount >= 1 && itemCount <= 3) {
					$('.banner').addClass('banner-1-menu-market');
				} else if (itemCount >= 4 && itemCount <= 6) {
					$('.banner').addClass('banner-2-menu-market');
				} else if (itemCount >= 7 && itemCount <= 9) {
					$('.banner').addClass('banner-3-menu-market');
				}
			}
		});



		/*	
		|
		|  Form externe
		|-----------------
		*/ 
		$(document).ready(function() {
			$('form').each(function() {
				var action = $(this).attr('action');
				if (action && action.includes('salesforce')) {
					$(this).addClass('form-salesforce');
				}
			});
		});


		/*	
		|
		|  Form externe
		|-----------------
		*/ 
		$(document).ready(function() {
			$('.form-salesforce select').each(function() {
				$(this).wrap('<div class="select-container"></div>');
			});
		});
		

		/*	
		|
		|  btn-see-more
		|-----------------
		*/ 
		jQuery(document).ready(function($) {
			$('.btn-see-more').on('click', function() {
				var $button = $(this);
				var $ul = $button.prev('ul');
				var $hiddenItems = $ul.find('.hidden');
		
				$hiddenItems.slice(0, $hiddenItems.length).slideToggle(300, function() {
					$(this).toggleClass('hidden');
				});
		
				$button.remove(); // Supprime le bouton après le clic
			});
		});
		
		
		


	}
}