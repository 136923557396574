export default {
    init: () => {
        function getFraction(height, width) {
            const gcd = (a, b) => b === 0 ? a : gcd(b, a % b)
            const divisor = gcd(height, width)
            return `${height / divisor}/${width / divisor}`
        }

        ///////////////////////////////////
        //
        //
        //          Click
        //
        //
        ///////////////////////////////////
        document.addEventListener('DOMContentLoaded', () => {
            const actualiteRessourceBlocks = document.querySelectorAll('.block-actualite-ressource a.tealium-event-promotion')
            const activitesBlocks = document.querySelectorAll('.block-activites a.tealium-event-promotion')
        
            const attachEventListener = (links, selfpromoType) => {
                links.forEach((link, index) => {
                    link.addEventListener('click', () => {
                        const selfpromoContent = link.querySelector('.cat').textContent.trim()
                        const selfpromoName = link.querySelector('h3').textContent.trim()
                        const selfpromoUrl = link.getAttribute('href')
                        const selfpromoPosition = (index + 1).toString()
                        const selfpromoFormat = getFraction(link.offsetHeight, link.offsetWidth)
                        
                        const eventData = {
                            'tealium_event': 'selfpromo_click',
                            'selfpromo_content': selfpromoContent,
                            'selfpromo_name': selfpromoName,
                            'selfpromo_url': selfpromoUrl,
                            'selfpromo_position': selfpromoPosition,
                            'selfpromo_type': selfpromoType,
                            'selfpromo_format': selfpromoFormat
                        }

                        if (typeof utag === 'object' && typeof utag.link === 'function') {
                            utag.link(eventData)
                        } else {
                            console.warn('Tealium utag.link function not available, self-promo click event not sent:', eventData)
                        }
                    })
                })
            }
        
            attachEventListener(actualiteRessourceBlocks, 'ressource')
            attachEventListener(activitesBlocks, 'offres')
        })


        ///////////////////////////////////
        //
        //
        //          Scroll
        //
        //
        ///////////////////////////////////
        document.addEventListener('DOMContentLoaded', () => {
            const promoLinks = document.querySelectorAll('.block-actualite-ressource a.tealium-event-promotion, .block-activites a.tealium-event-promotion')
        
            const observer = new IntersectionObserver((entries, observer) => {
                entries.forEach(entry => {
                    if (entry.isIntersecting && entry.intersectionRatio >= 0.5) {
                        const promoLink = entry.target
                        const block = promoLink.closest('.block-actualite-ressource, .block-activites')
                        const selfpromoContent = promoLink.querySelector('.cat').textContent.trim()
                        const selfpromoName = promoLink.querySelector('h3').textContent.trim()
                        const selfpromoUrl = promoLink.getAttribute('href')
                        const selfpromoPosition = Array.from(block.querySelectorAll('a.tealium-event-promotion')).indexOf(promoLink) + 1
                        const selfpromoType = block.classList.contains('block-actualite-ressource') ? 'ressource' : 'offres'
                        const selfpromoFormat = getFraction(promoLink.offsetHeight, promoLink.offsetWidth)
        
                        const eventData = {
                            'tealium_event': 'selfpromo_display',
                            'selfpromo_content': selfpromoContent,
                            'selfpromo_name': selfpromoName,
                            'selfpromo_url': selfpromoUrl,
                            'selfpromo_position': selfpromoPosition.toString(),
                            'selfpromo_type': selfpromoType,
                            'selfpromo_format': selfpromoFormat
                        }

                        if (typeof utag === 'object' && typeof utag.link === 'function') {
                            utag.link(eventData)
                        } else {
                            console.warn('Tealium utag.link function not available, self-promo display event not sent:', eventData)
                        }
        
                        observer.unobserve(promoLink)
                    }
                })
            }, {
                root: null,
                rootMargin: '0px',
                threshold: 0.5
            })
        
            promoLinks.forEach(link => observer.observe(link))
        })
    }
}