const DEFAULTS = {
    method: 'GET',
    responseType: 'json',
    data: {}
}

export default class Ajax {
    constructor(options = {}) {
        this.options = {
            ...DEFAULTS,
            ...options
        }

        this.options.data = new URLSearchParams(this.options.data)

        if (this.options.method.toUpperCase() === 'GET' && this.options.data) {
            const query = this.options.data.toString()
            this.options.url += query ? (this.options.url.includes('?') ? '&' : '?') + query : ''
        }

        this.xhr = new XMLHttpRequest()
        this.xhr.open(this.options.method, this.options.url)
        this.xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest')
        this.xhr.responseType = this.options.responseType
        this.xhr.onload = () => {
            if (this.xhr.status != 200) {
                this.options.onError(this.xhr.status, this.xhr.statusText)
                return
            }

            this.options.onSuccess(this.xhr.response)
        }
        this.xhr.onerror = this.options.onError
        this.xhr.onloadstart = this.options.onLoadStart
        this.xhr.onloadend = this.options.onLoadEnd

        if (this.options.method.toUpperCase() === 'GET') {
            this.xhr.send()
        } else {
            this.xhr.send(this.options.data)
        }
    }

    abort() {
        this.xhr.abort()
    }
}